<template>
  <!-- content -->
  <div class="blog-detail-wrapper">
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card
            img-top
            img-alt="Список"
            :title="`${list.name}`"
        >
          <b-button :disabled="isLoading" @click="createList"
                    :variant="isSuccess ? (list.exists ? 'primary' : 'success') : 'danger'">
            Сохранить список
          </b-button>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-around mb-2 mb-md-0"
            >
              <b-row>
                <b-col md="12">
                  <div
                      v-for="(movie,index) in movies"
                      :key="movie.id"
                      class="d-flex align-items-center"
                      :class="index === 0 ? 'mt-2' : 'mt-1'"
                  >
                    <b-avatar
                        v-if="movie.poster.previewUrl"
                        :src="movie.poster.previewUrl"
                        class="mr-50"
                        size="40"
                    />
                    <div class="user-page-info">
                      <h6 class="mb-0">
                        {{ movie.name }} / {{ movie.alternativeName }}
                        <span v-if="movie.year">({{ movie.year }})</span>
                      </h6>
                      <small class="text-muted">{{ movie.director }}</small>
                    </div>
                    <span class="font-weight-bold font-large-1 ml-1">
                        <span
                            :class="(movie.rating.kp < 5) ? 'text-danger' : (movie.rating.kp < 7) ? 'text-gray' : 'text-success'"
                        >
                          {{ movie.rating.kp }}
                        </span>
                      </span>
                    <div class="d-flex justify-content-start ml-auto align-items-center">
                      <div
                          v-if="movie.votes.kp"
                          class="mr-1"
                      >
                        {{ movie.votes.kp }}
                      </div>
                      <b-button
                          v-if="!movie.db"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="success"
                          class="btn-icon"
                          size="sm"
                          @click="addMovie(movie)"
                          :disabled="(disabled===movie.id)"
                      >
                        <feather-icon icon="UserPlusIcon"/>
                      </b-button>
                      <b-button
                          v-else
                          :to="{name: 'movie-view', params: { id: movie.db.id }}"
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                      >
                        <feather-icon icon="ArrowRightCircleIcon"/>
                      </b-button>
                      <b-button
                          :href="`https://kinopoisk.ru/film/${movie.id}`"
                          variant="warning"
                          class="btn-icon ml-1"
                          size="sm"
                      >
                        <feather-icon icon="CastIcon"/>
                      </b-button>
                    </div>

                  </div>

                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <!--/ blogs -->
    </b-row>
    <!--/ blogs -->
  </div>
  <!--/ content -->
</template>

<script>
import {
  BFormInput, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BLink, BFormGroup, BInputGroup, BInputGroupAppend,
  BCard, BRow, BCol, BBadge, BCardText, BDropdown, BDropdownItem, BForm, BFormTextarea, BFormCheckbox, BButton,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from '@/router'

export default {
  components: {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      list: {},
      movies: {},
      disabled: 0,
      isLoading: false,
      isSuccess: true,
    }
  },
  created() {
    let slug = router.currentRoute.params.slug
    this.$http.get(`/movie/collection?slug=${slug}`).then(res => {
      this.list = res.data.data
      this.movies = res.data.movies
    })
  },
  metaInfo: {
    title: 'Своя Игра ФНФ',
  },
  methods: {
    kFormatter,
    addMovie(movie) {
      this.disabled = movie.id
      this.$http.post(`/movie`, {kp_id: movie.id, 'title_ru': movie.name}).then(res => {
        let slug = router.currentRoute.params.slug
        this.$http.get(`/movie/collection?slug=${slug}`).then(res => {
          this.list = res.data.data
          this.movies = res.data.movies
        })
      })
    },
    createList() {
      let slug = router.currentRoute.params.slug
      this.isLoading = true
      this.$http.post(`/movie/collection?slug=${slug}`, {slug: slug,})
          .then(res => {
            this.isLoading = false
            this.isSuccess = true
            this.list.exists = true
          })
          .error(res => {
            this.isSuccess = false
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
